@import "~bootstrap/scss/bootstrap";

// Bootstrap functions
@import '~bootstrap/scss/functions';

.start-listening {
    padding: .5rem 1rem !important;
    background-color: #c11f36;
}

.shifts {
  font-style: italic;
  font-size: 4.25rem !important;
}

.shifts span {
  color: #c11f36 !important;
  font-weight: 700 !important;
}

#podcast-description {
  font-size: 1.55rem; 
  font-weight: 300;
}

.stream-section-btn {
  color: #c11f36 !important;
}

.social-icon {
  color: transparent !important;
  text-decoration-line: none !important;
}

.episode-row {
  background-color: #931b32 !important;
  color: #fff;
}

.call-to-action {
  padding-top: 10rem;
  padding-bottom: 10rem;
}